























































































import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import SellerList from "@/components/core/SellerList.vue";
import Learnlink from "@learnlink/interfaces";
import { getOnboardingSettings, updateOnboardingSettings } from "@/api/platform-settings-service";

export default defineComponent({
  name: "NewApplications",
  components: { SellerList },
  setup() {
    const vm = reactive({
      loading: false,
      onboardingSettings: {
        PLOnboardingMessage: "",
        PLOnboardingOpen: true,
        proOnboardingMessage: "",
        proOnboardingOpen: true,
      } as Learnlink.PlatformSettings.Onboarding,
    });

    onMounted(async () => {
      vm.loading = true;
      vm.onboardingSettings = await getOnboardingSettings();
      vm.loading = false;
    });

    async function update(): Promise<void> {
      vm.loading = true;
      await updateOnboardingSettings(vm.onboardingSettings);
      vm.loading = false;
    }

    return {
      update,
      vm
    };
  }
});
