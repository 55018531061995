







































































import SellerCard from "@/components/sellers/SellerCard.vue";
import Moment from "@/services/moment";
import Learnlink from "@learnlink/interfaces";
import { computed, defineComponent, PropType, reactive } from "@vue/composition-api";

export default defineComponent({
  components: { SellerCard },
  name: "SellerThumbnail",
  props: {
    seller: {
      type: Object as PropType<Learnlink.Seller.AdminThumbnail>,
      required: true
    }
  },
  setup(props) {
    const vm = reactive({
      dialogOpen: false,
    });
    const age = computed(() => Moment().diff(props.seller.account?.birthdate, "years"));
    const registerDate = computed(() => Moment.unix(props.seller.user.registerDate).format("Do MMM YYYY"));
    return {
      age,
      registerDate,
      vm,
    };
  }
});
