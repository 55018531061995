































import SellerList from "@/components/core/SellerList.vue";
import { axios } from "@/configuration";
import { defineComponent, reactive } from "@vue/composition-api";
import { sanitizeError } from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import store from "@/store/store";

export default defineComponent({
  name: "SearchSellers",
  components: { SellerList },
  setup() {
    const vm = reactive({
      categories: [] as string[],
      loading: false,
      sellers: [] as Learnlink.Seller.default[],
      query: "",
    });

    async function search() {
      vm.loading = true;
      vm.sellers = [];

      if (!vm.query) {
        store.commit("displayAlert", {
          color: "error",
          message: "Tomt søkefelt",
        });
      }
      else {
        try {
          if (vm.query.includes(" ")) vm.query = vm.query.replace(/\s/g, "");
          if (vm.query.includes("+47")) vm.query = vm.query.slice(3);
          const sellerRequest = await axios.get(`/admin/sellers/search/${vm.query}`);

          vm.sellers = sellerRequest.data;
          !vm.sellers.length && store.commit("displayAlert", {
            color: "error",
            message: "Ingen treff på søket",
          });
        }
        catch (e) {
          store.commit("displayAlert", {
            color: "error",
            message: sanitizeError(e).message,
          });
        }
      }

      vm.loading = false;
    }

    return {
      search,
      vm,
    };
  }
});
