








































































import { defineComponent } from "@vue/composition-api";
import NewApplications from "./NewApplications.vue";
import SearchSellers from "@/components/sellers/SearchSellers.vue";
import SellerList from "@/components/core/SellerList.vue";

export default defineComponent({
  name: "Sellers",
  components: {
    NewApplications,
    SearchSellers,
    SellerList,
  },
  metaInfo() {
    return {
      title: "Admin / LS",
    };
  },
});
