
































































import SellerThumbnail from "@/components/sellers/SellerThumbnail.vue";
import handleError from "@/helpers/errors";
import { computed, defineComponent, onMounted, PropType, reactive } from "@vue/composition-api";
import Learnlink from "@learnlink/interfaces";
import { getSellers } from "@/api/seller-service";

export default defineComponent({
  name: "SellerList",
  components: { SellerThumbnail },
  props: {
    path: {
      type: String as PropType<string>,
      default: "",
    },
    list: {
      type: Array as PropType<Learnlink.Seller.default[]>,
      default: () => [],
    },
  },
  setup(props) {
    const vm = reactive({
      filters: {
        onlyApprovedSellers: false,
        onlyPros: false,
      },
      loading: false,
      mounted: false,
      sellers: [] as Learnlink.Seller.default[],
    });

    onMounted(() => {
      vm.sellers = props.list;
    });

    const filteredSellers = computed(() => {
      return vm.sellers
        .filter(approvedFilter)
        .filter(proFilter);
    });

    function approvedFilter(seller: Learnlink.Seller.default): boolean {
      if (vm.filters.onlyApprovedSellers) return seller.profile.certificateApproved && seller.profile.policeRecordApproved;
      return true;
    }

    function proFilter(seller: Learnlink.Seller.default): boolean {
      if (vm.filters.onlyPros) return seller.user.pro > 0;
      return true;
    }

    async function search() {
      vm.mounted = true;
      vm.loading = true;

      try {
        vm.sellers = await getSellers(props.path || "list");
      }
      catch (e) {
        handleError(e);
        vm.sellers = [];
      }

      vm.loading = false;
    }

    return {
      filteredSellers,
      search,
      vm,
    };
  }
});
