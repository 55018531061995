import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"pa-2 text-left",on:{"click":function($event){_vm.vm.dialogOpen = true}}},[_c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"4","align":"center"}},[_c(VImg,{attrs:{"src":_vm.seller.profile.photoURL,"height":"160px","max-width":"160px"}}),(_vm.seller.user.pro)?_c(VImg,{staticClass:"ml-auto mr-n4 mt-n7",attrs:{"src":require("../../assets/pro.png"),"height":"40px","width":"40px"}}):_vm._e()],1),_c(VCol,{attrs:{"cols":"8"}},[_c('h4',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.seller.profile.firstName)+" "+_vm._s(_vm.seller.profile.lastName)+" ")]),_c('div',{staticClass:"small-text"},[_c('span',[_c('strong',[_vm._v("Alder:")]),_vm._v(" "+_vm._s(_vm.age))]),_c('br'),_c('span',[_c('strong',[_vm._v("Registrerte seg")]),_vm._v(" "+_vm._s(_vm.registerDate)+" ")]),_c('br'),_c('span',[_c('strong',[_vm._v("Telefon:")]),_vm._v(" "+_vm._s(_vm.seller.account.phone)+" ")]),_c(VDivider,{staticClass:"my-4"}),(_vm.seller.profile.numberOfCustomers)?_c('span',[_vm._v(" "+_vm._s(Math.round(_vm.seller.profile.minutesSold / 60))+" timer, "+_vm._s(_vm.seller.profile.numberOfCustomers)+" elever ")]):_c('span',[_c('strong',[_vm._v("Ingen erfaring")])])],1)])],1)],1),_c(VDialog,{attrs:{"fullscreen":""},model:{value:(_vm.vm.dialogOpen),callback:function ($$v) {_vm.$set(_vm.vm, "dialogOpen", $$v)},expression:"vm.dialogOpen"}},[_c('seller-card',{attrs:{"uid":_vm.seller.profile.uid},on:{"close":function($event){_vm.vm.dialogOpen = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }